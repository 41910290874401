import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'


const Hero = ({ className, isIndex = true, title }) => {
  const { hero, about, devoxx, voxxday, postponed } = useStaticQuery(
    graphql`
      query {
        hero: file(relativePath: { eq: "hero-banner.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 2840) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        about: file(relativePath: { eq: "about-voxxed-days-bg.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        devoxx: file(relativePath: { eq: "devoxx-high-res.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 150) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        voxxday: file(relativePath: { eq: "voxxed-days-logo.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        postponed: file(relativePath: { eq: "postponed.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `,
  )

  // Single Image Data
  const heroImg = isIndex ? hero.childImageSharp.fluid : about.childImageSharp.fluid
  const devoxxLogo = devoxx.childImageSharp.fluid
  const voxxdayBanner = voxxday.childImageSharp.fluid
  const postponedImg = postponed.childImageSharp.fluid

  return (
    <BackgroundImage
      Tag="section"
      className={className}
      fluid={heroImg}
      id="VoxxedHero"
      role="img"
      preserveStackingContext={true}
    >
      <div className="hero-head">
        <nav className="navbar">
          <div className="container is-uppercase">
            <div className="navbar-brand">
              <a href="https://devoxx.com/">
                <Img fluid={devoxxLogo}
                     style={{ minWidth: '150px' }}
                     alt={'Devoxx'}/>
              </a>
              <span className="navbar-burger burger" data-target="navbarMenuHeroA">
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
            </div>
            <div id="navbarMenuHeroA" className="navbar-menu">
              <div className="navbar-end">
                <Link to={'/#ABOUT'} className={'navbar-item'}>
                  About
                </Link>
                {/*<Link to={'/#KEY_DATES'} className={'navbar-item'}>*/}
                {/*  Schedule*/}
                {/*</Link>*/}
                <Link to="/agenda/" className={'navbar-item'}>Agenda</Link>
                <Link to="/speakers/" className={'navbar-item'}>Speakers</Link>
                <Link to={'/#TEAM'} className={'navbar-item'}>
                  Team
                </Link>
                <Link to="/gallery/" className={'navbar-item'}>Gallery</Link>
                {/*<Link to="/tickets/" className={'navbar-item'}>Tickets</Link>*/}
                <Link to="/code-of-conduct/" className={'navbar-item'}>Code of Conduct</Link>
                <Link to={'/#CONTACT'} className={'navbar-item'}>
                  Contact Us
                </Link>
                <span className="navbar-item">
            </span>
              </div>
            </div>
          </div>
        </nav>
      </div>

      <div className="hero-body">
        <div className="container has-text-centered">
          {isIndex && (
            <>
              <h1 className="is-hidden">Voxxed Day Downunder | Sydney | Melbourne</h1>
              <Img fluid={voxxdayBanner} className={'has-image-centered'} style={{ maxWidth: '1000px' }}/>
              <div
                className={'subtitle is-size-5-tablet is-size-6-mobile has-text-weight-bold has-padding-top-50 has-text-white'}>
                <div>
                  <span className="icon-text">
                    <span className="icon">
                      <i className="fas fa-calendar-alt"/>
                    </span>
                    <span>15th February 2022</span>
                    <span className="icon has-margin-left-30">
                      <i className="fas fa-map-pin"/>
                    </span>
                    <span>Melbourne</span>
                  </span>
                </div>
                <div className={'container has-margin-top-20'}>
                  <span className="icon-text">
                    <span className="icon">
                      <i className="fas fa-calendar-alt"/>
                    </span>
                    <span>17th February 2022</span>
                    <span className="icon has-margin-left-30">
                      <i className="fas fa-map-pin"/>
                    </span>
                    <span>Sydney</span>

                    {/*<div className={'is-overlay is-left'}>*/}
                    {/*  <Img fluid={postponedImg} className={'has-image-centered'}*/}
                    {/*       style={{ maxWidth: '80px' }}/>*/}
                    {/*</div>*/}
                  </span>
                </div>
              </div>

              {/*<div className="columns is-mobile has-margin-top-30 is-size-7">*/}
              {/*  <div className="column is-half-tablet is-three-fifths-mobile is-offset-one-fifth-mobile is-offset-one-quarter-tablet notification">*/}
              {/*    <Link to={'/tickets'} className="is-size-6">*/}
              {/*      <span>Buy tickets now!</span>*/}
              {/*    </Link>*/}
              {/*    <div className="has-text-weight-light">*/}
              {/*      <p>*/}
              {/*        ABSOLUTELY RISK-FREE, CANCEL ANYTIME WITH FULL REFUND*/}
              {/*      </p>*/}
              {/*      <p> READ THE COVID & REFUND POLICY{' '}*/}
              {/*        <Link to={'/covid-ticket-plan'}>HERE</Link>*/}
              {/*      </p>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </>
          )}

          {!isIndex && (
            <>
              <h2 className={'title is-2 has-text-weight-bold has-text-white'}>{title}</h2>
            </>
          )}

        </div>
      </div>
    </BackgroundImage>
  )
}

export default Hero
